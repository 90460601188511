import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, NavLink, useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import StrongPassMsgComp from '../../expocomp/StrongPassMsgComp';
//import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GSingUpcomponent from './gSignupComp';
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Register = () => {

  const [serverResMessage, setServerResMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [borderCSS, setBorderCSS] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [signuptype, setSignuptype] = useState('jobseeker');
  const [isPasswordStrong, setIsPasswordStrong] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isCodeExist, setIsCodeExist] = useState(false);
  const showAndHidePassword = () => { setShowPassword(!showPassword); }
  const navigate = useNavigate();
  const callCallBack = () => { setTimeout(function () { setServerResMessage('') }, 3000); }
  const query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 210);
    const auth = localStorage.getItem('squadGologin');
    if (auth) { navigate('/'); }
    const code = query.get('referralcode');
    if (code) { setReferralCode(code); setIsCodeExist(true) }
  });

  const checkAlpahbate = (value, name) => {
    const alphanumericRegex = /^[a-zA-Z]*$/;
    if (alphanumericRegex.test(value)) {
      if (name == 'firstName') { setFirstName(value); }
      if (name == 'lastName') { setLastName(value); }
    }
  }

  //handleContactChange

  const [emailExists, setEmailExists] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailChange = async (value) => {
    //const value = e.target.value;
    setEmail(value);
    const response = await fetch(`https://squadgoo.com.au/api.squadgoo.com.au/check_email_existance/${value}`);
    const exists = await response.json();
    setServerResMessage('');
    setEmailExists(exists);
  };


  //confirmPassword, setConfirmPassword

  const checkPasswordStrength = (password) => {
    // Define your criteria for a strong password here
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumbers &&
      hasSpecialChars
    );
  };


  /*----------------------------------------*/
  const [serverMessage, setServerMessage] = useState('');

  //passwordsMatch("confirm password should be matched with Password")
  const [criteria, setCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const [isFocused, setIsFocused] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setCriteria({
      length: value.length >= 8,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[!@#$%^&*]/.test(value),
    });
    setPasswordsMatch(value === confirmPassword);
  };




  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordsMatch(value === password);
    (value != password) && setServerMessage('confirm password should be matched with Password');
    (value == password) && setServerMessage('');
  };

  const isPasswordValid = Object.values(criteria).every(Boolean);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (!isPasswordValid) {
      setIsFocused(false);
    }
  };

  /*
   setContactNumberCode
  setContactNumber
  */

  // const handleContactChange = (number) => {
  //   setContactNumberCode(number);
  // }

  const [contactNumberError, setContactNumberError] = useState('');

  const [contactNumberCode, setContactNumberCode] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const handleContactChange = (number) => {
    const value = number;
    const regex = /^[0-9\b]+$/; setContactNumber('');
    if (value === '' || regex.test(value)) {
      setContactNumber(value);
      setContactNumberError('');
      setContacClassError('');
      if (value.length > 9) {
        // if (storedContactNumber != value) {
        (async () => {
          try {
            const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/mobilecheck/${'test'}/${value}`);
            if (resData.data.status == 1) {
              setContactNumber('');
              setContactNumberError(resData.data.message);
            }
          } catch (error) { console.log(error); }
        })();
        // }
      }
    }
  }


  const [checkRefferalCodeMessage, setCheckRefferalCodeMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const [isAppliedReferalCode, setIsAppliedReferalCode] = useState(false);

  const checkReferralCode = async (code) => {
    try {
      const resData = await axios.get(`https://squadgoo.com.au/api.squadgoo.com.au/check_refferalcode/${code}`);
      setErrorMessage('');
      setIsAppliedReferalCode(false)
      if (resData.data.status === 1) {
        setErrorMessage('<span class="text-success">Varified</span>');
        setIsAppliedReferalCode(true)
      } if (resData.data.status === 0) {
        setErrorMessage('<span class="text-danger">Not Varified</span>');
      }


      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return () => clearTimeout(timer);

    } catch (error) {
      console.log('Error checking referral code:', error);
    }
  };

  const checkRefferalCodeFun = (e) => {
    let refCode = e.target.value;
    setReferralCode(refCode);

    setErrorMessage('');
    if (refCode.length < 1 || refCode.length > 12) {
      //setErrorMessage("Referral code must be between 1 and 12 characters.");
      return;
    }

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const newTimeout = setTimeout(() => {
      checkReferralCode(refCode);
    }, 500);

    setDebounceTimeout(newTimeout);
  }


  const [selectedCode, setSelectedCode] = useState('91'); // Default value set to '+91'

  // Array of country codes and their corresponding display values
  const countryCodes = [
    { value: '61', label: '+61' },
    { value: '91', label: '+91' },
    { value: '64', label: '+64' },
  ];

  const handleChange = (event) => {
    setSelectedCode(event.target.value);
  };

  /*----------------------------------------------------*/

  const [fileLoadingContent, setLoadingContent] = useState(0);
  const [contacClassError, setContacClassError] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    setServerResMessage('');
    let isChildFieldValid = true;

    if (!contactNumber) {
      setContacClassError('error_border');
      isChildFieldValid = false;
    }
    if (contactNumber && contactNumber.length < 10) {
      setContacClassError('error_border');
      isChildFieldValid = false;
    }
    //if contactNumber

    //error_border
    if ((form.checkValidity() === false) || !isChildFieldValid) { event.stopPropagation(); }
    if (!isPasswordValid) { setServerResMessage("Please enter valid password"); callCallBack(); }
    else if (!passwordsMatch) { setServerResMessage("confirm password should be matched with Password"); }
    else {
      const formData = new FormData();
      formData.append('signuptype', signuptype);
      formData.append('first_name', firstName);
      formData.append('last_name', lastName);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('applied_referralcode', (isAppliedReferalCode ? referralCode : ''));

      setLoadingContent(1);
      axios.post('https://squadgoo.com.au/api.squadgoo.com.au/signup', formData)
        .then((response) => {
          if (response.data.status == 1) { navigate('/verification?id=' + response.data.user_id); setLoadingContent(0); }
          else {
            setLoadingContent(0);
            setServerResMessage(response.data.message);
            //setServerResMessage(response.data.field_status);
            setBorderCSS(true);
            if (response.data.field_status != 4) {
              callCallBack();
            }
          }
        }).catch((error) => { console.error(error); });
    }
    setValidated(true);
  };

  return (
    <>



      <div className="loginsignupwrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="loginsignupwrapformwrap">




                <Form noValidate validated={validated} onSubmit={handleSubmit} className="commonform lofinsignupform registerform">
                  <div className="formbranding text-center">
                    <img src="/front/images/squadlogo.png"></img>
                  </div>
                  <h2 className="text-center">Create your account for</h2>

                  <div className="form-group mb-2 text-center">
                    <Form.Group controlId="switch" className="switch-field">
                      {/* I'm a  */}
                      {/* <Form.Check type="switch" id="switch" label="Toggle switch" checked={switchOn} onChange={() => setSwitchOn(!switchOn)} /> */}
                      <input type="radio" id="jobseeker" name="switch1" value={signuptype} checked={signuptype == 'jobseeker' ? true : false} onClick={() => setSignuptype("jobseeker")} />
                      <label htmlFor="jobseeker">Jobseeker</label>
                      <input type="radio" id="rec_reprasentative" name="switch1" value={signuptype} checked={signuptype == 'rec_reprasentative' ? true : false} onClick={() => setSignuptype("rec_reprasentative")} />
                      <label htmlFor="rec_reprasentative">Recruiter</label>
                      <input type="radio" id="rec_indivisual" name="switch1" value={signuptype} checked={signuptype == 'rec_indivisual' ? true : false} onClick={() => setSignuptype("rec_indivisual")} />
                      <label htmlFor="rec_indivisual">Indvidual</label>
                    </Form.Group>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">First Name<span className="starr">*</span></label>
                    <div className="iconfields">
                      <img className="fieldicons" src="/front/images/name.svg"></img>
                      <input
                        type="text" className="form-control namefield"
                        placeholder="Enter your first name"
                        value={firstName}
                        onChange={(event) => checkAlpahbate(event.target.value, 'firstName')}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Last Name<span className="starr">*</span></label>
                    <div className="iconfields">
                      <img className="fieldicons" src="/front/images/name.svg"></img>
                      <input
                        type="text" className="form-control namefield"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={(event) => checkAlpahbate(event.target.value, 'lastName')}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label">Email Address<span className="starr">*</span></label>
                    <div className="iconfields">
                      <img className="fieldicons" src="/front/images/message.svg"></img>
                      <input
                        type="email"
                        className={borderCSS ? 'form-control emailfield bordercss' : 'form-control emailfield'}
                        placeholder="Enter your email address"
                        value={email}
                        onChange={(event) => handleEmailChange(event.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div class="form-group mb-2">
                    <label class="form-label">Phone number <span class="starr">*</span></label>
                    <div class={`phonefield d-flex align-items-center ${contacClassError}`}>
                      <select className="form-control" name="contact_country_code" value={selectedCode} onChange={handleChange}>
                        {countryCodes.map((code) => (
                          <option key={code.value} value={code.value}>
                            {code.label}
                          </option>
                        ))}
                      </select>
                      <input type="tel"
                        class="form-control"
                        required={true}
                        name="contact_number"
                        placeholder="Enter mobile number"
                        maxLength={10}
                        value={contactNumber}
                        onChange={(event) => handleContactChange(event.target.value)}
                      />
                    </div>
                    {contactNumberError && <div className='uniquenu_error'>
                      {contactNumberError}
                    </div>}
                  </div>



                  {/* <div className="form-group mb-2">
                    <label className="form-label">Enter your password<span className="starr">*</span></label>
                    <div className="passwordwrap p-relative iconfields">
                      <img className="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control passwordfield"
                        placeholder="Enter your password"
                        value={password}
                        name="password"
                        onChange={(event) => setPasswordFun(event.target.value)}
                        required
                      />
                      <span onClick={showAndHidePassword} className="eyebtn">
                        {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>
                    </div>
                    {
                      password && !isPasswordStrong && <StrongPassMsgComp />
                    }
                  </div> */}

                  <div className="form-group mb-2">
                    <label htmlFor="password">Password</label>
                    <div className="passwordwrap p-relative iconfields">
                      <img className="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control passwordfield"
                        placeholder="Enter your password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        required
                      />
                      <span onClick={showAndHidePassword} className="eyebtn">
                        {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>
                    </div>


                    {isFocused && !isPasswordValid && (
                      <ul className="password-criteria">
                        <li style={{ color: criteria.length ? 'green' : 'red' }}>
                          At least 8 characters
                        </li>
                        <li style={{ color: criteria.uppercase ? 'green' : 'red' }}>
                          At least one uppercase letter
                        </li>
                        <li style={{ color: criteria.lowercase ? 'green' : 'red' }}>
                          At least one lowercase letter
                        </li>
                        <li style={{ color: criteria.number ? 'green' : 'red' }}>
                          At least one number
                        </li>
                        <li style={{ color: criteria.specialChar ? 'green' : 'red' }}>
                          At least one special character (!@#$%^&*)
                        </li>
                      </ul>
                    )}

                  </div>







                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="passwordwrap p-relative iconfields">
                      <img className="fieldicons" src="/front/images/lock.svg"></img>
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control passwordfield"
                        placeholder="Enter confirm password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                      />
                      <span onClick={showAndHidePassword} className="eyebtn">
                        {showPassword ? <img src="/front/images/eyeclose.svg"></img> :
                          <img src="/front/images/eyeopen.svg"></img>}
                      </span>
                    </div>
                    {serverMessage && <div className='text-danger'>{serverMessage}</div>}
                  </div>

                  {/* {isPasswordValid && passwordsMatch && (
                    <div className="valid-password" style={{ color: 'green' }}>
                      Password meets all requirements and passwords match.
                    </div>
                  )} */}


                  {!isCodeExist && <div className="form-group mb-2 text-center mt-2">
                    {/*  */}
                    <input type="text" class={`referral_code text-center ${isAppliedReferalCode ? 'success_border' : 'error_border'}`} placeholder="Referral Code" required=""
                      value={referralCode}
                      onChange={checkRefferalCodeFun}
                    // onChange={(event) => setReferralCode(event.target.value)} 
                    />
                    <div
                      dangerouslySetInnerHTML={{ __html: errorMessage }}  // Render HTML response here
                    />
                  </div>}




                  <Form.Group className="form-group mb-2 commonchecbox2 pt-2">
                    <Form.Check
                      required
                      id="referalCodeId"
                      className='paddingofcheckbox'
                      label={<><label for="referalCodeId" className="checklabel ml-1 form-label ">
                        I agree to the Squad Goo
                        <Link target="_blank" to={`/terms-and-conditions?usertype=${signuptype}`} className='ml-2'>Terms and Conditions</Link> and
                        <Link target="_blank" to={`/privacy-policy`} className='ml-2'>Privacy Policy</Link>.
                      </label></>
                      }
                      feedback="You must agree before submitting."
                      feedbackType="invalid"
                    />
                    {serverResMessage ? <p className="text-danger text-center  mb-0">{serverResMessage}</p> : ''}
                  </Form.Group>

                  <div className="form-group mb-2">
                    {/* <button disabled={(password && !isPasswordStrong) ? false : true} type="Submit" className="batn batn-orange"  >Join Squad Goo</button> */}
                    <button type="submit" className="batn batn-orange m-0" disabled={fileLoadingContent}  >

                      {fileLoadingContent ? 'Please wait....' : 'Join Squad Goo'}
                    </button>
                    {/* <input type="Submit" className="batn batn-orange" value="Join Squad Goo"></input> */}
                  </div>



                </Form>

                <GoogleOAuthProvider clientId="68903946618-gnk17k93slg69rhdbg5pdnee2brbo6to.apps.googleusercontent.com">
                  <GSingUpcomponent usertype={signuptype} />
                </GoogleOAuthProvider>

                <div className="text-center formbtmtxt">
                  <p>Already have an account?   <NavLink to="/login" className="formlink text-safron">Log In</NavLink></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;


